function getYzsFileServer(
  config = { basePrefix: "/gw", bizKey: "", bizType: "", code: "" }
) {
  //根据自己的参数获取上传服务实例
  // eslint-disable-next-line no-undef
  return new YzsFileService({
    generateMate: function () {
      return {
        code: config.code,
        client: "PC",
        tag: "default",
        time: Date.now(),
      };
    },
    generateHeaders: function () {
      return {
        Authorization: localStorage.getItem("iam-token"),
      };
    },
    // 覆盖前面的代码，
    ...config,
  });
}

/**
 * 公告编辑  上传图片
 */
const carNoticeEditorUploadServer = getYzsFileServer({
  basePrefix: "/gw",
  bizKey: "basics",
  bizType: "basics",
  code: "customer",
});


/**
 * 车辆跟踪统计表  发送图片 发送文件
 */
const carMessHotlineFileUploadServer = getYzsFileServer({
  basePrefix: "/gw",
  bizKey: "driver-communication-new",
  bizType: "driver-communication-new",
  code: "extra",
});


/**
 * 进口未排计划  放箱
 */
const f2eimportNotDischargedFileUploadServer = getYzsFileServer({
 
  basePrefix: "/gw",
  bizKey: "release-container",
  bizType: "release-container",
  code: "document-and-certificate",
});

/**
 * 群发消息 群发热线
 */
const f2emassHotlineFileUploadServer = getYzsFileServer({
  basePrefix: "/gw",
  bizKey: "operate",
  bizType: "operate",
  code: "future-driver",
});


/**
 * 建档申请 新客户上传营业执照
 */
const f2efilingApplicationFileUploadServer = getYzsFileServer({
  basePrefix: "/gw",
  bizKey: "business-license",
  bizType: "business-license",
  code: "customer",
});

/**
 * 客户档案-编辑工商资料
 */
const f2ecustomerManageFileUploadServer = getYzsFileServer({
  basePrefix: "/gw",
  bizKey: "business-license",
  bizType: "business-license",
  code: "customer",
});


/**
 * 运力运营处理，司机问题相关附件，如司机热线，司机投诉，违规司机处理，请假。
 */
const dispatchDriverCareFileUploadServer = getYzsFileServer({
  basePrefix: "/gw",
  bizKey: "driver-care-file",
  bizType: "driver-care-file",
  code: "dispatch",
});


/**
 * 司机热线、内部协同、客户热线 聊天文件
 */
const extraDriverCommunicationNew = getYzsFileServer({
  basePrefix: "/gw",
  bizKey: "driver-communication-new",
  bizType: "driver-communication-new",
  code: "extra",
});


module.exports = {
  f2ecustomerManageFileUploadServer,
  f2efilingApplicationFileUploadServer,
  f2emassHotlineFileUploadServer,
  f2eimportNotDischargedFileUploadServer,
  dispatchDriverCareFileUploadServer,
  carMessHotlineFileUploadServer,
  carNoticeEditorUploadServer,
  extraDriverCommunicationNew
};
