<template>
    <div id="response-modal" class="modal fade dk-modal" mode="out-in" :class="[show ? 'in': '', ...className]" style="z-index: 110000 !important" @click='bodyClick($event)'>
        <div v-show='contentShow' ref="modalFull" class="modal-dialog modal-full">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click="toggleModal" aria-label="Close" aria-hidden="true">
                        <i class="iconfont icon-close"></i>
                    </button>

                    <h4 v-if="key=='driver'" class="modal-title" style="font-weight: bold;">接单人:{{driverNameList.takerName}}({{driverNameList.auditStatus}})
                        &nbsp;&nbsp;&nbsp;司机：{{driverNameList.name}}&nbsp;&nbsp;{{driverNameList.truckPlateNumber}}
                        <span v-if="driverNameList.isVIP">&nbsp;&nbsp;({{driverNameList.vipGrade}}VIP)</span>
                        <span v-if="driverNameList.isBlockedDriver">&nbsp;&nbsp;(黑名单)</span>
                    </h4>
                    <h4 v-else class="modal-title" style="font-weight: bold;">{{title}}</h4>
                </div>
                <div class="modal-body" @dblclick='showImg($event)' style="overflow:hidden;paddingBottom:0">
                    <div style="overflow: hidden" v-if="checkDriverCommunication()">
                        <dkSelectSateOfFive title="做箱时间：" :value="boxDate" class="five-day-picker" @change="handleTabDateChange" :isRange="false" ref="datePicker"/>
                    </div>
                    
                    <ul v-if="taskInfoList.length>1 && key == 'internal'" class="nav nav-tabs no-margin-bottom">
                        <li :class="{active:config.containerId == container.containerId}" v-for="container in taskInfoList" :key="container.id">
                            <a data-toggle="tab" @click="config.containerId = container.containerId;curQuote.content = '';curQuote.id = '';initInfo()"> 
                                {{container.orderAndSeqId}}
                            </a>
                        </li>
                    </ul>
                    <div v-if='(config.taskId || config.containerId) && taskInfoList.length' class="note note-info">
                        <div v-if="isTASK_SHORT_BARGE(taskTypeId)">
                            <span>司机：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.driverName }}</span>
                            <span>车牌号：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.truckPlateNumber }}</span>
                            <span>手机号：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.driverPhone }}<dkIconPhone v-if="shortBargeTask.driverPhone" :value="shortBargeTask.driverPhone" class="telCircle" :id="curSelectedGroup.id || createdBy"></dkIconPhone></span>
                            <span>客户：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.customerName }}</span>
                            <span>任务类型：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.typeName }}</span>
                            <br>
                            <span>箱型：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.containerSize }}</span>
                            <span>下单时间：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.startDate }}</span>
                            <span>截至时间：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.closingDate }}</span>
                            <br>
                            <span>提箱点：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.pickupPoint }}</span>
                            <span>还箱点：</span><span style="font-weight:600;margin-right: 10px;">{{ shortBargeTask.returnPoint }}</span>
                        </div>
                        <div v-else>
                            <div v-for='item in taskInfoList' style="padding: 5px 0;display:flex;justify-content: space-between" v-if='key != "internal" || config.containerId == item.containerId' :key="item.id">
                                <div style="position:absolute;top:0;right:30px;color:#3a80d7;cursor:pointer;" @click="showDetialBang(item.receiptNo)" v-if="item.receiptNo">查看eir</div>
                                <div>
                                    <span v-for="(value, key,index) in taskInfoKeys"  :key="value.id">
                                        <span>
                                            <span>{{value}}</span>
                                            <span style="font-weight:600">{{item[key]||""}}</span>
                                            <a v-if='driverInfoShowCommDriver && index==0' @click='driverInfoCommDriver'>(联系司机)</a>
                                            <dkIconPhone v-if="key == 'driverPhone' && item[key]" :value="item[key]" :id="curSelectedGroup.id || createdBy" class="telCircle"></dkIconPhone>
                                        </span>
                                        <br v-if="index==4 || index==8" />
                                    </span>
                                    <span style="color:red;margin-left:10px">({{filterCustomerConfirmedPlan(item.hasCustomerConfirmedPlan)}})</span>
                                    <span v-if="item.containerNo && item.blNo"><a @click="gotoCarTraceReport(item.containerNo,item.blNo)">车辆跟踪统计表</a></span>
                                </div>
                                <div>
                                    <el-popover
                                        placement="left"
                                        width="400"
                                        trigger="hover">
                                    <span>{{item.specialRequirement}}</span>
                                    <span slot="reference" v-show="item.hasSpecialRequirement"> <img  src="../images/icon_te.png" style="width: 18px;margin-left: 10px;"></span>
                                    </el-popover>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="config.businessType == 'SHIPPING_BOOKING_INQUIRY_ORDER' && shippingBookingInquiryData.length" class="note note-info">
                        <el-row v-for="(sbi,sbii) in shippingBookingInquiryData" :key="sbii">
                            <el-col :span="24">
                                <b>询价单</b>
                                <el-row>
                                    <el-col :span="8"><span>询价单号：{{sbi.inquiryNumber}}</span></el-col>
                                    <el-col :span="4"><span>箱型：{{ sbi.shippingBookingContainerDtos.length ? sbi.shippingBookingContainerDtos[0].containerSizeType : ''}}</span></el-col>
                                    <el-col :span="6"><span>品名：{{sbi.productName}}</span></el-col>
                                    <el-col :span="6">
                                        <span>客户目标价：{{ sbi.customerTargetPrice ? `$${sbi.customerTargetPrice}` : '' }}</span>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="8"><span>起运港：{{ sbi.portOfDeparture }}</span></el-col>
                                    <el-col :span="4"><span>箱量：{{ sbi.shippingBookingContainerDtos.length ? sbi.shippingBookingContainerDtos[0].containerNum : ''}}</span></el-col>
                                    <el-col :span="6"><span>预计装货时间：{{ sbi.estimatedLoadingTime }}</span></el-col>
                                    <el-col :span="6"><span>偏好船公司：{{ sbi.preferredShippingCompanies }}</span></el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="8"><span>目的港：{{ sbi.portOfDestination }}</span></el-col>
                                    <el-col :span="4"><span>货重：{{ sbi.estimatedWeight }}吨</span></el-col>
                                    <el-col :span="6"><span>危险品或化工品：{{ sbi.isDangerousGoods ? '是' : '否'}}</span></el-col>
                                    <el-col :span="6"><span>备注：{{sbi.remark}}</span></el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="24">
                                <el-tabs v-model="activeName">
                                    <el-tab-pane :label="'报价单'+(index+1)" :name="index+''" v-for="(sbitem,index) in sbi.shippingBookingQuotationDto" :key="sbitem.shippingBookingQuotationId">
                                        <el-row>
                                            <el-col :span="8"><span v-for="pi in sbitem.shippingBookingPrice">海运费用：{{ pi.currency == 'USD' ? '$' : '￥' }}{{ pi.amount }}</span></el-col>
                                            <el-col :span="4"><span>航线：{{ sbitem.route }}</span></el-col>
                                            <el-col :span="6"><span>报价有效期：{{ sbitem.quotationValidityTimeFrom.split(" ")[0] }}至{{ sbitem.quotationValidityTimeTo.split(" ")[0] }}</span></el-col>
                                            <el-col :span="6"><span>其他费用：{{ sbitem.shippingBookingPrice[0].remark }}</span></el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8"><span>船公司：{{ sbitem.shippingCompany }}</span></el-col>
                                            <el-col :span="4"><span>航期：{{sbitem.navigationMode}} {{ sbitem.shippingDays ? sbitem.shippingDays + '天' : '' }}</span></el-col>
                                            <el-col :span="6"><span>船名航次：{{ sbitem.vessel }}/{{sbitem.voyage}}</span></el-col>
                                            <el-col :span="6"><span>船期：{{ sbitem.shippingDate ? sbitem.shippingDate.split(" ")[0] : '' }}</span></el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="6"><span>报价人：{{ sbitem.bidderName }}</span></el-col>
                                        </el-row>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="key=='driver' && taskInfoList && taskInfoList.length > 0" style="margin-bottom: 5px;overflow: hidden; width: 100%">
                        <button v-if="shwoTruckBtn" class="other-info-btn btn btn-default" @click="clickBtn('car-trace-report')">车辆位置</button>
                        <button v-if="shwoSearchOrderBtn" class="other-info-btn btn btn-default" @click="clickBtn('new-order-inquiry')">订单查询</button>
                        <button v-if="shwoToCostBtn" class="other-info-btn btn btn-default" @click="clickBtn('driver-cost')">应付司机</button>
                        <button class="other-info-btn btn btn-default" @click="getDoorName" v-if="config.containerId">催装货</button>
                        <button class="other-info-btn btn btn-default" @click="getSupplementaryBussiness(taskInfoList)">补业务登记</button>
                        <span class="unhandle-count">未完成：{{unhandleCount}}</span>
                    </div>
                    <div style="float:left">
                        <div style="paddingRight:5px;width:566px">
                            <div ref='infoList' class="communication-body info-content" style="overflow-y:auto;">
                                <ul class="info-list" style="width:100%;">
                                    <li class="info-item" v-for="item in replyList" :key="item.creationTime">
                                        <div class="time">
                                            <span>{{item.creationTime}}</span>
                                        </div>
                                        <!-- <div class="avatar" style="float:right;">我的头像</div> -->
                                        <div :class="{'fl':(oppositeName == '未认证司机' || oppositeName == item.createdByName || item.judge == '0'),'fr':(oppositeName != item.createdByName || item.judge == '1')}">
                                            <span>{{item.createdByName}} 
                                                <span v-if="item.forwardFrom" style="color: red">(来自司机【{{item.forwardFrom}}】的信息, 该回复将直接发送给司机)</span>
                                            </span>
                                        </div>
                                        <div class="clear"></div>
                                        <div class="info" :class="{'left-info':(oppositeName == '未认证司机' || oppositeName == item.createdByName || item.judge == '0'),'right-info':(oppositeName != item.createdByName || item.judge == '1')}">
                                            <div v-if="item.beRepliedPostingMap" class="quote-reply" v-html="analysisContent(item.beRepliedPostingMap)"></div>
                                            <div v-if='item.messageType == "FILE"' :title='item.content'>
                                                <div class="info-file">
                                                    <i class="fa fa-file-o"></i>
                                                    <div class="info-file-name">{{item.content}}</div>
                                                    <div class="info-file-name file-note">点击下载</div>
                                                    <div class="info-file-name file-note" style="display:flex;">
                                                        <div style="margin-right: 5px;" @click='downloadFile(item.content,item.filePath)'>点击下载</div>
                                                        <div @click='openFileFn(item.filePath)'>点击预览</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if='key != "customer" && item.messageType == "IMAGE"'>
                                                <img :src="item.content">
                                            </div>
                                            <div v-else-if='item.messageType == "BARGAINING"'>
                                                <span>新的客户议价申请<a :href="item.content" @click="complete">点此跳转议价申请</a></span>
                                            </div>
                                            <div v-else-if='item.messageType == "AUDIO"'>
                                                <a class="fa fa-bullhorn info-audio" :href='"cheatcodec"+item.content.substring(4)'></a>
                                            </div>
                                            <div v-else-if='item.messageType == "VIDEO"' class="video-box">
                                                <dkVideoBox :video-info="item.content"></dkVideoBox>
                                            </div>
                                            <!-- 回复文本信息 -->
                                            <div v-else :data-name='item.messageType'>
                                                <span v-html="analysisContent(item.content)" style="word-break: break-all;"></span>
                                                <template v-if="checkDriverCommunication()">
                                                    <div v-if="(oppositeName == '未认证司机' || oppositeName == item.createdByName || item.judge == '0')" class="reply-toolbox">
                                                        <span @click="handleReply(item)">回复</span>&nbsp;&nbsp;
                                                        <span @click="handleCompleteTask(item)">完成</span>&nbsp;&nbsp;
                                                        <span @click="handleTodo(item)">待办</span>&nbsp;&nbsp;
                                                        <span @click="handleTurnOver(item)">移交</span>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <component :is="DriverReset" :params="paramsData"></component>
                            <div class="send-body info-content" :style="{height: curQuote.content ? '180px' : ''}">
                                <div v-if='emoticonModal.fade' class="emoticon-box">
                                    <!-- <div class="emoticon-header"></div> -->
                                    <div class="emoticon-table">
                                        <ul style="padding:15px 10px 15px 15px;overflow: hidden;">
                                            <li v-for='(v,k) in emoticonsList' :key="k" @click='addEmoticon(k)' :title='k' :class="v" class="emoticon-item"></li>
                                        </ul>
                                    </div>
                                </div>
                                <div style='overflow: hidden;'>
                                    <div :id="'editor_tool' + initId" class="toolbar editor-tool"></div>
                                    <div class="tool">
                                        <div class="tool-item" title='表情' @click='emoticonModal.fade = !emoticonModal.fade' v-if='key != "driver"'>
                                            <i class="fa fa-smile-o"></i>
                                        </div>
                                        <div class="tool-item" title='上传文件' @click='fileClick' v-if='config.editorTool && config.editorTool.indexOf("file") != -1'>
                                            <i class="fa fa-folder-o"></i>
                                            <div class="tool-input">
                                                <input ref='fileInput' type="file" @change='getFile'>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if='config.changeInfo' class="change-info">
                                        <a class="change-item" @click='changeNext'>下一条</a>
                                        <a class="change-item" @click='changePre'>上一条</a>
                                    </div>
                                </div>
                                <div class="quote message-nowrap" v-if="curQuote.content">
                                    <p v-html="curQuote.content" class="message-nowrap-c"></p>
                                    <button type="button" class="close" style="position: absolute; top:0;right:5px;" @click="delReply" aria-label="Close" aria-hidden="true">
                                        <i class="iconfont icon-close"></i>
                                    </button>
                                </div>
                                <div>
                                    <div v-show="hasReplyIcon">
                                        <!-- consoleLog("发送消息 keydown----"); -->
                                        <div ref='editor' class='editor-text' :id="'editor_text' + initId" @keydown.enter='sendInfo("","",$event)'></div>
                                    </div>
                                    <div v-show="!hasReplyIcon" style="color: #ccc;margin-left: 1.5rem;">系统消息不支持回复！ </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer" style="position:relative;overflow:hide;">
                            <button type="button" class="btn btn-default negative" style="float:left; padding: 3px 10px; margin-right: 10px" @click='toggleModal()'>关闭</button>
                            <div>
                                <div v-if='config.buttons && config.buttons.indexOf("containerOrder") != -1' class="other-info-btn btn btn-default" @click='blShow = true;blNo = ""'>反馈装箱单</div>
                                <div v-if='config.buttons && config.buttons.indexOf("truckMap") != -1' class="other-info-btn btn btn-default">查车辆位置</div>
                            </div>
                            <!-- consoleLog("发送消息 点击发送 ----"); -->
                            <button v-if='config.buttons && config.buttons.indexOf("send") != -1 && hasReplyIcon' type="button" class="btn btn-primary negative" style="float: right;" @click='sendInfo()'>发送</button>
                            <button v-if='config.buttons && config.buttons.indexOf("complete") != -1 && key != "driver"' type="button" class="btn btn-primary negative" style="float: right;margin-right:15px" @click="reqInfo">完成</button>
                            <!-- <button 
                                v-if='config.buttons && config.buttons.indexOf("complete") != -1 && key == "driver"' 
                                type="button" 
                                class="btn btn-primary negative" 
                                style="float: right;margin-right:15px" 
                                @click="handleCompleteAllTask">
                                全部完成
                            </button> -->
                            <button v-if='config.buttons && config.buttons.indexOf("wait") != -1 && !checkDriverCommunication()' type="button" class="btn btn-primary negative" style="float: right;margin-right:15px" @click='waitInfo()'>待办</button>
                        </div>
                    </div>
                    <div style="float:left;width:400px;height:450px;paddingLeft:5px">
                        <div ref='historyInfoList' class="info-content" style="overflow-y:auto;height:100%;marginBottom:0" @scroll='hisScroll'>
                            <ul class="info-list">
                                <li class="info-item" v-for="item in hisInfoList" :key="item.creationTime">
                                    <div class="time">
                                        <span>{{item.creationTime}}</span>
                                    </div>
                                    <!-- <div class="avatar" style="float:right;">我的头像</div> -->
                                    <div :class="{'fl':item.judge == '0','fr':item.judge == '1'}">
                                        <span>{{item.createdByName}}</span>
                                    </div>
                                    <div class="clear"></div>
                                    <div class="left-info-box" :class="{'flex-direction':item.judge == '1'}">
                                        <div class="info" :class="{'left-info':item.judge == '0','right-info':item.judge == '1'}">
                                            <div v-if="item.beRepliedPostingMap && item.beRepliedPostingMap.content" class="quote-reply" v-html="analysisContent(item.beRepliedPostingMap.content)"></div>
                                            <div v-if='!isEmptyObject(item.map)' class="reply-info" style='color:#777;padding: 0 10px;;border-bottom:1px solid #000;margin-bottom:5px'>
                                                <div style="margin-bottom:5px">
                                                    <span>{{item.map.createdByName}}&nbsp;&nbsp;</span>
                                                    <span>{{item.map.creationTime}}</span>
                                                </div>
                                                <div v-if='item.map.messageType == "FILE"' :title='item.map.content'>
                                                    <div class="info-file">
                                                        <i class="fa fa-file-o"></i>
                                                        <div class="info-file-name">{{item.map.content}}</div>
                                                        <div class="info-file-name file-note" style="display:flex;">
                                                            <div style="margin-right: 5px;" @click='downloadFile(item.map.content,item.map.filePath)'>点击下载</div>
                                                            <div @click='openFileFn(item.map.filePath)'>点击预览</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p v-if='item.map.messageType == "TEXT"' style="word-break: break-all;">{{item.map.content}}</p>
                                                <img v-if='key != "customer" && item.map.messageType == "IMAGE"' :src="item.map.content">
                                                <a v-if='item.map.messageType == "AUDIO"' class="fa fa-bullhorn info-audio" :href='"cheatcodec"+item.map.content.substring(4)'></a>
                                                <div v-else-if='item.messageType == "VIDEO"' class="video-box">
                                                    <dkVideoBox :video-info="item.content"></dkVideoBox>
                                                </div>
                                                <div v-else-if='item.messageType == "BARGAINING"'>
                                                    <span>新的客户议价申请<a :href="item.content">点此跳转议价申请</a></span>
                                                </div>
                                                <div v-if='!item.map.messageType || item.map.messageType == "HTML" || (key == "customer" && item.map.messageType == "IMAGE")' v-html="analysisContent(item.map.content)" style="word-break: break-all;"></div>
                                            </div>
                                            <div v-if='item.messageType == "FILE"' :title='item.content'>
                                                <div class="info-file">
                                                    <i class="fa fa-file-o"></i>
                                                    <div class="info-file-name">{{item.content}}</div>
                                                    <div class="info-file-name file-note" style="display:flex;">
                                                        <div style="margin-right: 5px;" @click='downloadFile(item.content,item.filePath)'>点击下载</div>
                                                        <div @click='openFileFn(item.filePath)'>点击预览</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p v-if='item.messageType == "TEXT"' style="word-break: break-all;">{{item.content}}</p>
                                            <img v-if='key != "customer" && item.messageType == "IMAGE"' :src="item.content">
                                            <a v-if='item.messageType == "AUDIO"' class="fa fa-bullhorn info-audio" :href='"cheatcodec"+item.content.substring(4)'></a>
                                            <div v-else-if='item.messageType == "VIDEO"' class="video-box">
                                                <dkVideoBox :video-info="item.content"></dkVideoBox>
                                            </div>
                                            <div v-else-if='item.messageType == "VIDEO"' class="video-box">
                                                <dkVideoBox :video-info="item.content"></dkVideoBox>
                                            </div>
                                            <div v-else-if='item.messageType == "BARGAINING"'>
                                                <span>新的客户议价申请<a :href="item.content">点此跳转议价申请</a></span>
                                            </div>
                                            <div v-else-if='!item.messageType || item.messageType == "HTML" || (key == "customer" && item.messageType == "IMAGE")' v-html="analysisContent(item.content)" style="word-break: break-all;"></div>
                                        </div>
                                        <div class="reply-btn" v-if="item.hasReplyIcon && key == 'internal'" @click="handleReply(item)">回复</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show='contentShow' class="modal-backdrop fade" :class="{'in':show}"></div>
        <dk-modal title='鸭嘴兽' :style-objs='{"width":"600px"}' :fade='imgShow' @change='imgShow = false;'>
            <dk-image :style-objs='{"height":"400px"}' :images='imagesDetailList'></dk-image>
        </dk-modal>
        <dk-modal title='消息对象' :style-objs='{"width":"830px"}' :fade='modalChoose.fade' @change='toggleModal();' class="response-group-modal" :class="[...className]">
            <div class="group-list">
                <div class="group-item" v-for="(item, itemIndex) in chatGroupList">
                    <h3 class="group-title">{{item.name}}</h3>
                    <div class="child-list">
                        <el-button 
                            size="mini"
                            v-for="(child, childIndex) in item.children"
                            :key="childIndex"
                            :class="[{elBtnActive: selectedBtnIndexChild === `p${itemIndex}c${childIndex}`}]"
                            @click='chatTo(child.name, child, item, {itemIndex:itemIndex, childIndex:childIndex, childType: "child"})'
                            style="white-space:pre-wrap;text-align:left">{{child.name}}</el-button>
                    </div>
                    <div class="child-list secondChild-list" v-if="item.secondChildren && item.secondChildren.length > 0">
                        <el-button 
                            size="mini"
                            v-for="(child, childIndex) in item.secondChildren"
                            :key="childIndex"
                            :class="[{elBtnActive: selectedBtnIndexSecondChild === `p${itemIndex}sc${childIndex}`}]"
                            @click='chatTo(child.name, child, item, {itemIndex:itemIndex, childIndex:childIndex, childType: "second"})'
                            style="white-space:pre-wrap;text-align:left">{{child.name}}</el-button>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <button type="button" class="btn btn-default" @click="toggleModal()">关闭</button>
                <button type="button" class="btn btn-default" @click="conformMessage">确认</button>
            </div>
        </dk-modal>
        <!-- 选择多地装具体地址发送 -->
        <dk-modal title='多地装业务，请先选择一个门点地址' :style-objs='{"width":"600px"}' :fade='doorShow' @change='doorShow = false;'>
            <div v-for="item in doorlist"  @click="chooseDoor(item.doorAddress)" style="margin-right:20px">
              <input type="radio" :value="item.doorAddress" v-model="selectDoor">  <span style="font-size:14px;">  {{item.doorAddress}}</span>
            </div>

            <div slot="footer">
                <button type="button" class="btn btn-default" @click="doorShow = false;">关闭</button>
                <button type="button" class="btn btn-primary" @click="confirmdoorShow">确定</button>
            </div>
        </dk-modal>

        <dk-modal title='输入提单号' :style-objs='{"width":"275px"}' :fade='blShow' @change='blShow = false;'>
            <div class="bs-example">
                <dk-input label='提单号' :value="blNo" @input='getInput("blNo",$event)'></dk-input>
            </div>
            <div slot="footer">
                <button type="button" class="btn btn-default" @click="blShow = false;">关闭</button>
                <button type="button" class="btn btn-primary" @click="confirmBl">确定</button>
            </div>
        </dk-modal>
        <dk-modal title='车辆位置' :style-objs='{width: "700px"}' :fade='modalMap.fade' @change="modalMap.fade = false">
            <dk-truck-map :style-objs='{height: "500px"}' :objs="modalMap.param"></dk-truck-map>
        </dk-modal>
        <dk-modal title='确认' :style-objs='{width: "300px"}' :fade='modalReq.fade' @change="modalReq.fade = false">
            <p style="text-align:center;">当前消息没有回复，确定完成吗？</p>
            <div slot="footer">
                <button type="button" class="btn btn-default" @click="modalReq.fade = false;">关闭</button>
                <button type="button" class="btn btn-primary" @click="complete">确定</button>
            </div>
        </dk-modal>
        <!-- <dk-turn-over :fade="true"> -->
        <dkSupplementaryBusiness  :config="modalSupplementaryBusiness.config" :show="modalSupplementaryBusiness.fade" @change="modalSupplementaryBusiness.fade = false" :type="modalSupplementaryBusiness.type"></dkSupplementaryBusiness>
        <dk-eir ref='reseir'></dk-eir>
    </div>
</template>

<script>
import Editor from "../libs/wangEditor";
import dkImage from "dk-look-picture";
import dkModal from "dk-modal";
import dkInput from "dk-input";
import dkIconPhone from "dk-icon-phone";
import dkEir from "dk-eir";
import dkSelectSateOfFive from "dk-select-date-of-five";

import dkTruckMap from "dk-truck-map";
import dkVideoBox from "dk-video-box";
import dkSupplementaryBusiness from "dk-supplementary-business-modal"
import {
    shortBargeDriverTask,
    replyDriverMessage,
    fetchDriverChatList,
    fetchDispatchGroupByCase,
    fetchTakerByContainerId,
    fetchCustomerServiceByContainerIdOrTaskId
} from "../api/driverChat";

import { extraDriverCommunicationNew } from "../api/upload-common-server"
const { loadExternalBase } = require('../js/loadExternalService')
// 获取当天日期
function getNowFormatDate (date) {
    var date = date || new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}
export default {
    name: "dk-response-modal",
    data() {
        return {
            selectDoor:'',
            doorShow:false,
            doorlist:[],
            keyData: {
                customer: {
                    info: "/messenger/customer/posting",
                    reply: "/messenger/cs/reply",
                    complete: "/messenger/cs/done",
                    listName: "postingReplyRecord",
                    history: "/messenger/synchronize/customer/posting/record"
                },
                driver: {
                    info: "/messenger/driver/posting",
                    reply: "/messenger/dispatcher/reply",
                    complete: "/messenger/dispatcher/completed",
                    listName: "dispatcherReplyInfoDtos",
                    history: "/messenger/synchronize/driver/posting/record"
                },
                internal: {
                    info: "/messenger/internal/posting/record",
                    reply: "/messenger/send/internal/posting",
                    complete: "/messenger/internal/posting/done",
                    listName: "dispatcherReplyInfoDtos",
                    history: "/messenger/internal/posting/recordAll"
                }
            },
            taskInfoKeys: {
                driverName: "司机：",
                truckPlateNumber: "车牌号：",
                driverPhone: "手机号：",
                customerName: "客户：",
                containerNo: "箱号：",
                containerSizeType: "箱型：",
                weight: "货重：",
                // oppositeName: "司机：",
                planArrivalTime: "做箱时间：",
                blNo: "提单号：",
                portDistrict: "港区：",
                depot: "堆场：",
                doorAddress: "门点地址：",
                remark: "备注：",
                customerService: "客服：",
                
            },
            taskTypeId: '',
            shortBargeTask: {},
            key: "",
            initId: '',
            sendValue: "",
            createdBy: "",
            toGroup: '',
            toGroupId: '',
            driverPostingInfo: '',
            replyList: [],
            hasReplyIcon: true,
            editor: "",
            uploadImgUrl: "/ws-truck/messenger/upload/image",
            isEditing: false,
            imgShow: false,
            imagesDetailList: [],
            sendImages: [],
            hisInfoList: [],
            isReqHis: true,
            pageNum: 1,
            pageTotal: 0,
            blShow: false,
            blNo: "",
            taskInfoList: [],
            oppositeName: "",
            modalMap: {
                fade: false,
                param: {
                    plateNumber: ""
                }
            },
            modalSupplementaryBusiness:{
                fade:false,
                config:{},
                type:'SJRX'
            },
            modalReq: {
                fade: false
            },
            driverNameList: '',
            modalChoose: {
                fade: false,
                value: ''
            },
            contentShow: false,
            emoticonModal: {
                fade: false
            },
            emoticonsList: {
                '[笑脸]': 'emoticon_xiaolian',
                '[大笑]': 'emoticon_daxiao',
                '[酷]': 'emoticon_ku',
                '[微笑]': 'emoticon_weixiao',
                '[眨眼]': 'emoticon_zhayan',
                '[色]': 'emoticon_se',
                '[飞吻]': 'emoticon_feiwen',
                '[亲亲]': 'emoticon_qinqin',
                '[害羞]': 'emoticon_haixiu',
                '[满意]': 'emoticon_manyi',
                '[露齿笑]': 'emoticon_luchixioa',
                '[戏弄]': 'emoticon_xinong',
                '[调皮]': 'emoticon_tiaopi',
                '[馋]': 'emoticon_chan',
                '[得意]': 'emoticon_diyi',
                '[鄙视]': 'emoticon_bishi',
                '[汗]': 'emoticon_han',
                '[失望]': 'emoticon_shiwang',
                '[低落]': 'emoticon_diluo',
                '[呸]': 'emoticon_pei',
                '[焦虑]': 'emoticon_jiaolv',
                '[担心]': 'emoticon_danxin',
                '[震惊]': 'emoticon_zhenjing',
                '[悔恨]': 'emoticon_huihen',
                '[白眼]': 'emoticon_baiyan',
                '[大哭]': 'emoticon_daku',
                '[笑哭]': 'emoticon_xiaoku',
                '[闭嘴]': 'emoticon_bizui',
                '[思考]': 'emoticon_sikao',
                '[惊讶]': 'emoticon_jingya',
                '[吐钱]': 'emoticon_tuqian',
                '[生气]': 'emoticon_shengqi',
                '[惊恐]': 'emoticon_jingkong',
                '[生气2]': 'emoticon_shengqi2',
                '[气愤]': 'emoticon_qifen',
                '[犯困]': 'emoticon_fankun',
                '[生病]': 'emoticon_shengbing',
                '[恶魔]': 'emoticon_emo',
                '[花]': 'emoticon_hua',
                '[太阳]': 'emoticon_taiyang',
                '[月亮]': 'emoticon_yueliang',
                '[星星]': 'emoticon_xingxing',
                '[西瓜]': 'emoticon_xigua',
                '[啤酒]': 'emoticon_pijiu',
                '[撒花]': 'emoticon_sahua',
                '[红心]': 'emoticon_hongxin',
                '[心碎]': 'emoticon_xinsui',
                '[丘比特]': 'emoticon_qiubite',
                '[铃铛]': 'emoticon_lingdang',
                '[喇叭]': 'emoticon_laba',
                '[礼物]': 'emoticon_liwu',
                '[钱]': 'emoticon_qian',
                '[吹气]': 'emoticon_chuiqi',
                '[雨滴]': 'emoticon_yudi',
                '[多云]': 'emoticon_duoyun',
                '[下雨]': 'emoticon_xiayu',
                '[火]': 'emoticon_huo',
                '[握手]': 'emoticon_woshou',
                '[赞]': 'emoticon_zan',
                '[弱]': 'emoticon_ruo',
                '[好的]': 'emoticon_haode',
                '[拳头]': 'emoticon_quantou',
                '[拳头2]': 'emoticon_quantou2',
                '[耶]': 'emoticon_ye',
                '[向上]': 'emoticon_xiangshang',
                '[向下]': 'emoticon_xiangxia',
                '[向右]': 'emoticon_xiangyou',
                '[向左]': 'emoticon_xiangzuo',
                '[手指]': 'emoticon_shouzhi',
                '[双手]': 'emoticon_shuangshou',
                '[鼓掌]': 'emoticon_guzhang',
                '[拜托]': 'emoticon_baituo',
                '[加油]': 'emoticon_jiayou',
                '[写字]': 'emoticon_xiezi',
                '[日历]': 'emoticon_rili',
                '[邮件]': 'emoticon_youjian',
                '[钻石]': 'emoticon_zuanshi',
                '[闹钟]': 'emoticon_naozhong',
                '[上锁]': 'emoticon_shangsuo',
                '[开锁]': 'emoticon_kaisuo',
                '[车]': 'emoticon_che',
                '[信号灯]': 'emoticon_xinhaodeng',
                '[路障]': 'emoticon_luzhang',
                '[港口]': 'emoticon_gangkou',
                '[船]': 'emoticon_chuan',
                '[火箭]': 'emoticon_huojian',
                '[炸弹]': 'emoticon_zhadan',
                '[厕所]': 'emoticon_cesuo',
                '[停车]': 'emoticon_tingche',
                '[拍照]': 'emoticon_paizhao',
                '[禁烟]': 'emoticon_jinyan',
                '[禁]': 'emoticon_jin',
                '[禁止]': 'emoticon_jinzhi',
                '[禁止2]': 'emoticon_jinzhi2',
                '[错误]': 'emoticon_cuowu',
                '[感叹]': 'emoticon_gantan',
                '[双叹]': 'emoticon_shuangtan',
                '[问号]': 'emoticon_wenhao',
                '[疑问]': 'emoticon_yiwen',
                '[警告]': 'emoticon_jinggao',
                '[满分]': 'emoticon_manfen',
                '[祝福]': 'emoticon_zhufu',
                '[Mac]': 'emoticon_Mac',
                '[Wac]': 'emoticon_Wac',
                '[警察]': 'emoticon_jingcha',
            },
            isCustomerButton: false,  //是否在联系司机的面板上显示联系客户按钮
            companyName: '', //公司的名称,
            isCustomerBox: false,
            curQuote: {
                content: ""
            },
            replyData: null,   //  回复的消息数据
            curCompleteId: "", // 单条完成 id 记录
            boxDate: new Date(),
            unhandleCount: 0,
            chatGroupList: [],
            curSelectedGroup: {},
            initialId:'',
            selectedBtnIndexChild: null,
            selectedBtnIndexSecondChild: null,
            messageData: {},
            shippingBookingInquiryData:[],
            saleInfo: {},
            businessInfo: {},
            activeName:"0",
            seconds: 76666,
            driverRest:false,
            paramsData: {},
            DriverReset: {
                    render: h => h('div', '加载中')
            },
        }
    },
    props: {
        title: {
            type: String,
            default: "未认证司机"
        },
        show: {
            type: Boolean,
            default: false
        },
        needChoose: {
            type: Boolean,
            default: true
        },
        config: {
            type: Object,
            default: {
                id: "",
                key: "",
                changeInfo: false,
                editorTool: [],
                buttons: [],
                isShowCustomer: false,
                clientName: '',
                createdName: '',
                customerId: '',
                toGroup:'',
                targetId: '',
                ifQueryContainerNoBook: false,
                businessType:"",
                shippingPersonnel:""
            }
        },
        className: {
            type: Array,
            default: () => []
        }
    },
    components: {
        "dk-image": dkImage,
        "dk-modal": dkModal,
        "dk-input": dkInput,
        
        "dk-truck-map": dkTruckMap,
        dkVideoBox,
        "dk-eir":dkEir,
        dkIconPhone,
        dkSelectSateOfFive,
        dkSupplementaryBusiness,
        // 'dk-paging': dkPaging,
    },
    directives: {
        focus: {
            update: function(el, { value }) {
                if (value) {
                    el.focus();
                }
            }
        }
    },
    computed:{
        shwoTruckBtn:function(){
            return  this.key=='driver'
        },
        shwoToCostBtn:function(){
            var flag = this.key=='driver';

            if(window.location.hash=='#driver-cost'){
                flag = false;
            }
            return  flag;
        },
        shwoSearchOrderBtn:function(){
            var flag = this.key=='driver';

            if(window.location.hash=="#new-order-inquiry"){
                flag = false;
            }
            return  flag;
        },
        driverInfoShowCommDriver: function() {
            //在内部协同的司机信息里添加联系司机
            return ((['智能调度组', '提箱组', '工厂组', '进港还箱组', '司机服务组'].includes(esapp.user.dispatcherGroup)) 
            && window.location.hash.indexOf("internal-coordination") >=0 
            && this.config.driverInfoCanCommDriver)
        },
        convertSeconds(){
            const hour = Math.floor(this.seconds / 3600);
            const minute = Math.floor((this.seconds % 3600) / 60);
            const second = this.seconds % 60;
            return `${hour}小时${minute}分钟${second}秒`
        }
    },
    created() {
        this.initEmoticonStyle()
        if(!this.initId) this.initId = new Date().getTime()
    },
    mounted() {
        console.log("dk----消息对象", this.config)
        loadExternalBase('component:dk-driver-reset').then(component => {
              this.DriverReset = component
            })
    },
    methods: {
        queryDriverMsg(takerId){
            console.log("queryDriverMsg",this.config)
            this.paramsData = {
                userId: takerId,
            } 
        },
        getSupplementaryBussiness(data){
            console.log('modalSupplementaryBusiness',this.modalSupplementaryBusiness,data);
            this.modalSupplementaryBusiness.config = data[0]
            this.modalSupplementaryBusiness.fade = true
        },
        consoleLog(info) {
            console.log(info)
        },
        chooseDoor:function(item){
            this.selectDoor = item;
        },
        confirmdoorShow:function(){
            var that=this;
            if(that.selectDoor==''){
                _.ui.notify({
                    message: "请选择地址",
                    type: "error"
                });
                return;
            }
            _.ui.mask();
            _.util.ajax_get('/messenger/prompt/load', {
                doorAddress:that.selectDoor,
                messageType:'催装货',
                driverId:that.config.oppositeId,
                postingId:that.config.id,
                containerId: that.config.containerId
            },function(res){
                _.ui.unmask();
                that.doorShow=false;
                if(res.success){
                    
                    _.ui.notify({
                        message: "发送成功",
                        type: "success"
                    });
                    that.toggleModal();
                }else{
                    
                    _.ui.notify({
                        message: "发送失败",
                        type: "error"
                    });
                    
                }
            })
            
        },
        getDoorName:function(){
            _.ui.mask();
            this.selectDoor=""
            this.doorlist=[];
            var that=this;
            _.util.ajax_get("/messenger/doorAddress/list", {driverId: that.config.oppositeId}, res => {
                console.log("res:",res)
                _.ui.unmask();
                // 调试用
                // that.doorShow=true;

                if(res.code=="SUCCESS"){
                    if(res.data.value){
                        var list=JSON.parse(res.data.value)
                        console.log('list:',list)
                        if(list.length>1){
                            //渲染选择
                            that.doorlist=list;
                            that.doorShow=true;
                        }else{
                            _.util.ajax_get('/messenger/prompt/load', {
                                    doorAddress:'',
                                    messageType:'催装货',
                                    driverId:that.config.oppositeId,
                                    postingId:that.config.id,
                                    containerId: that.config.containerId
                                },function(res){
                                    _.ui.unmask();
                                    that.doorShow=false;
                                    if(res.success){
                                        
                                        _.ui.notify({
                                            message: "发送成功",
                                            type: "success"
                                        });
                                        // that.toggleModal();
                                    }else{
                                        
                                        _.ui.notify({
                                            message: "发送失败",
                                            type: "error"
                                        });
                                        
                                    }
                                })
                        }
                    }else{
                        _.ui.notify({
                            message: "发送失败",
                            type: "error"
                        });
                    }
                }else{
                }
            })
        },
        showDetialBang:function(receiptId){
            if(!receiptId){return;}
            var that = this;
            this.$refs.reseir.showDetialBang(receiptId)
        },
        filterCustomerConfirmedPlan(value){
            if(value === true){
                return "客服已确认做箱"
            }else{
                return "客服未确认做箱"
            }
        },
        initEmoticonStyle() {
            let inlinecss = '',
                count = -1,
                lineNum = 15

            for(let k in this.emoticonsList){
                let x = 1-parseInt(++count%lineNum)*30
                let y = 0-parseInt(count/lineNum)*30
                inlinecss += '.' + this.emoticonsList[k] + ' {background-position: ' + x + 'px ' + y + 'px;}'
            }

            let style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = inlinecss;
            console.log('style',style)
            document.getElementsByTagName('HEAD').item(0).appendChild(style);
        },
        clickBtn(type) {
            if (type == "car-trace-report") {
                var data = {
                    doorAddress: "",
                    plateNumber: this.config.plateNumber || this.taskInfoList[0].truckPlateNumber
                };
                this.modalMap.param = data;
                this.modalMap.fade = true;
                return;
            } if (type === 'driver-cost') {
                window.location.href = "#" + type + "?orderAndSeqNo=" + this.taskInfoList[0].orderAndSeqId + '&resetForm=true' + '&planArriveTime=' + this.taskInfoList[0].planArrivalTimeSource.split(" ")[0]
            } else {
                window.location.href = "#" + type + "?orderAndSeqNo=" + this.taskInfoList[0].orderAndSeqId;   
            }
        },
        toggleModal() {
            this.show = false;
            this.modalChoose.fade = false;
            this.isCustomerBox = false;
            this.curQuote.content = ""
            this.$emit("change", this.show);
            if (this.$refs['datePicker']) {
                this.$refs['datePicker'].getNow()
            }
        },
        conformMessage() {
            // 非线路港口
            if (!this.chatGroupList.some((group) => {return group.secondChildren && group.secondChildren.length})) {
                _.ui.notify({
                    message: "请选择消息选项",
                    type: "error"
                });
                return
            }
            if (!this.config.baseDispatchGroupCode) { // 处理组必选
                _.ui.notify({
                    message: "请选择对应的处理组",
                    type: "error"
                });
                return
            }
            this.modalChoose.fade = false
            this.contentShow = true
            let firstname = this.messageData.childName
            firstname = firstname ? firstname + '-' : ''
            this.title = firstname + this.messageData.secondChildName
            this.$nextTick(() => {
                this.initInfo('','');
            })
        },
        initInfo(taskId,takerId) {
            // 接单人Id为空
            if (this.key != 'internal' && !this.config.oppositeId && !taskId){
                return
            }
            var that = this;
            this.oppositeName = this.title.split("--")[0];
            // this.key = this.key;
            this.createdBy = takerId || this.config.oppositeId;
            this.sendImages = [];
            this.taskInfoList = [];
            this.$nextTick(() => {
                that.initEditor();
                if (this.config.initContent)
                    this.editor.txt.html(
                        "<p>" + this.config.initContent + "</p>"
                    );
            });
            _.ui.mask();
            if ((this.config.taskId || this.config.containerId || taskId) && this.config.businessType != 'SHIPPING_BOOKING_INQUIRY_ORDER') {
                var sendData = {};
                var detailUrl = "/driver/cost/detail";

                // const driverId = that.key=== 'driver' ? that.curSelectedGroup.id : that.config.oppositeId || ''
                if (taskId) {
                    sendData.taskId = taskId
                } else if (that.config.ifQueryContainerNoBook) { // 如果是预提
                    sendData = {
                        id: that.config.taskId
                    }
                } else { // 不是预提
                    if (that.config.containerId) {
                        sendData = {
                            containerId:that.config.containerId
                        }
                    } else if (that.config.taskId) {
                        sendData = {
                            id: that.config.taskId
                        }
                    } else {
                        throw new Error("司机热线订单条接口调用错误")
                    }
                }
                sendData.flag = 'posting' // 司机热线做一个标记
                _.util.ajax_get(detailUrl, sendData, async res => {
                    // _.ui.unmask();
                    if(res.data) {
                        res = eval("(" + res.data.value + ")");
                        res.forEach(function(v){
                            v.planArrivalTimeSource = v.planArrivalTime
                            v.planArrivalTime = _.util.dateFormat(v.planArrivalTime)
                        })
                        if(!this.config.containerId) this.config.containerId = res[0].containerId;
                        this.taskInfoList = res;

                        if (this.isTASK_SHORT_BARGE(res[0].taskTypeId) && res[0].taskId) { // 短驳
                            this.taskTypeId = res[0].taskTypeId
                            const shortBargeData = await shortBargeDriverTask({ taskId: res[0].taskId })
                            if (shortBargeData.data.success) {
                                this.shortBargeTask = shortBargeData.data.data
                            }
                        } else {
                            this.taskTypeId = ''
                        }
                    }
                    this.getHisInfo(this.pageNum);
                });
            }else if (this.config.businessType == 'SHIPPING_BOOKING_INQUIRY_ORDER'){
                this.queryShippingBookingInquiry()
                this.getHisInfo(this.pageNum);
            }else{
                this.getHisInfo(this.pageNum);
            }
            if(this.key == 'driver' && !this.driverNameList) {
                this.getDriverInfo(this.curSelectedGroup.id || this.createdBy)
            }
            this.pageNum = 1;
            this.hisInfoList = [];
            this.isReqHis = true;
        },
        queryShippingBookingInquiry(){
            console.log("查询海运订舱的信息",this.config)
            let url = this.config.shippingPersonnel == 'sale' ? 'internal-sale' : 'internal'
            _.util.ajax_submit(`/../gw/customer-server/shipping/booking-inquiry/query-shipping-booking-inquiry-${url}`, {
                    meta:{
                        client:"F2E",
                        code:"XXR1804201034",
                        tag:"string",
                        time: new Date().getTime()
                    },
                    params:{
                        shippingBookingQuotationId: this.config.taskId,
                        type:"internal_posting",
                        page: 1,
                        pageSize: 30,
                    }
            }, res => {
                _.ui.unmask();
                let info = res.data
                info.forEach(e => {
                    e.shippingBookingQuotationDto = e.shippingBookingQuotationDto.filter(e => e.shippingBookingQuotationId)
                });
                this.activeName = '0'
                this.shippingBookingInquiryData = info
                
            });
        },
        isTASK_SHORT_BARGE(taskTypeId) {
            const taskList = [
                "TASK_GATE_IN_SHORT",       // 20 二进（进重） 
                'TASK_SCHEDULED_ACCRUAL',   // 30 预提（提空）
                'TASK_EMPTY_TRANS',         // 40 驳空（疏港）
                'TASK_INTERIOR_EMPTY_TRANS',// 50 仓库业务（提空）
                'TASK_INTERIOR_GATE_IN',    // 51 仓库业务（进重）
                'TASK_INTERIOR_HEAVY_TRANS',// 52 仓库业务（提重）
                'TASK_INTERIOR_EMPTY_TURN', // 53 仓库业务（还空）
                'TASK_EMPTY_TRANS_TURN',    // 60 还空任务
                'TASK_HEAVY_TRANS',         // 70 驳重（疏港）
            ]
            return taskList.includes(taskTypeId)
        },
        openFileFn(path) {
            _.util.openFileFn(path);
        },
        getDriverInfo(takerId) {
            _.util.ajax_get("/messenger/drivers/truckPlateNumber", {
                    takerId: takerId
                }, res => {
                    _.ui.unmask();
                    if(res.data&&res.data.value){
                         res = eval("(" + res.data.value + ")");
                    }else{
                        res = '该司机未认证'
                    }
                   
                    this.driverNameList = res
            });
            console.log(this.driverNameList)
            
        },
        async getHisInfo(num) {
            var that = this;
            let scrollHeight = that.$refs.historyInfoList.scrollHeight;
            if (this.keyData[this.key].history) {
                var sendData = "";
                if (this.key == "customer") {
                    //zyh
                    if(this.isCustomerBox) {
                        sendData = {
                            customerId: that.config.customerId,
                            targetId: that.config.createdBy || "",
                            containerId: that.config.containerId,
                            page: num,
                            pageSize: 15
                        };
                    }else {
                        sendData = {
                            customerId: that.config.oppositeId,
                            targetId: that.config.createdBy || "",
                            containerId: that.config.containerId,
                            page: num,
                            pageSize: 15
                        };
                    }
                    
                } else if (this.key == "internal") {
                    sendData = {
                        taskId: that.config.taskId || '',
                        containerId: that.config.containerId || '',
                        group: esapp.user.dispatcherGroup || '客服组',
                        dispatchGroupId: esapp.user.dispatchGroupId,
                        containerId: that.config.containerId,
                        page: num,
                        pageSize: 15
                    };
                    if (this.config.businessType == 'SHIPPING_BOOKING_INQUIRY_ORDER'){
                        sendData.businessType = 'shipping_booking_inquiry_order'
                        sendData.taskId = that.config.taskId || ''
                    }
                }

                let list = [];

                // 特殊处理 driver
                if (this.key === 'driver') {
                    const params = {
                        containerId: that.config.containerId,
                        takerId: this.curSelectedGroup.id,
                        dispatchGroupId: this.config.toGroupId || 1,
                        baseDispatchGroupCode: this.config.baseDispatchGroupCode,
                    }
                    // 联系司机 历史记录列表
                    const resp = await fetchDriverChatList({
                        params,
                        page: {
                            pageNo: num,
                            pageSize: 15,
                        }
                    })
                    // list = [{}, {}]
                    // 映射数据
                    list = _.get(resp, 'data.data.list', []).map(v => {
                        let data = {
                            content: v.content,
                        };
                        if (v.messageType === "VIDEO") {
                            data.content = Object.assign({ isPlay: false }, JSON.parse(v.content))
                        }
                        
                        return Object.assign({}, v, {
                            ...data,
                            judge: v.position === false ? "0" : "1"
                        })
                    })
                    that.pageTotal = _.get(resp, "data.data.total", 0)
                    cb()
                    return
                }
                _.util.ajax_get(
                    that.keyData[that.key].history,
                    _.util.removeEmptyValue(sendData),
                    res => {
                        _.ui.unmask();
                        if (!res.data) return;
                        res = eval("(" + res.data.value + ")");
                        that.pageTotal = res.total;
                        if (this.key == "driver") {
                            // list = res.driverPostingRecordDtos;
                        } else {
                            list = res.list;
                        }
                        if(this.key == 'internal'){
                            list.forEach(v => {
                                v.content = v.sendContent
                                v.creationTime = v.sendCreationTime
                                v.createdByName = v.driverPostingDirectTakerName ? v.driverPostingDirectTakerName : v.sendCreatedByName
                            })
                        }
                        cb()
                    }
                );
                
                function cb() {
                    that.hisInfoList = list.concat(that.hisInfoList);
                    console.log('hisInfoList------',that.hisInfoList);
                    that.$nextTick(() => {
                        if (num == 1) {
                            that.$refs.historyInfoList.scrollTop = that.$refs.historyInfoList.scrollHeight;
                        } else {
                            that.$refs.historyInfoList.scrollTop = that.$refs.historyInfoList.scrollHeight - scrollHeight;
                        }
                        that.isReqHis = false;
                    });
                }
            }
            // 当前对话
            if (this.config.id && this.initialId == this.config.containerId) {
                _.util.ajax_get(
                    that.keyData[that.key].info,
                    {
                        id: that.config.id
                    },
                    res => {
                        _.ui.unmask();
                        res = eval("(" + res.data.value + ")");
                        console.log('当前对话----------->',res)
                        var firstInfo = '';

                        // YYLY-865 针对系统发送的消息，一律不支持回复（包含引用回复和对话框直接回复）
                        if(this.key == 'internal'){
                            this.hasReplyIcon = res.hasReplyIcon
                        } else {
                            this.hasReplyIcon = true
                        }

                        if(this.key == 'internal'){
                            firstInfo = {
                                id: res.id,
                                content: res.sendContent,
                                creationTime: res.sendCreationTime,
                                messageType: res.messageType,
                                createdByName: res.driverPostingDirectTakerName ? res.driverPostingDirectTakerName : res.sendCreatedByName,
                                forwardFrom: res.takerName,
                                filePath: res.filePath || "",
                                group: esapp.user.dispatcherGroup || '客服组',
                                judge: res.judge || '0'
                            }
                            for (let item of that.replyList) {
                                if (item.id == firstInfo.id) {
                                    return
                                }
                            }
                            that.replyList.push(firstInfo)
                        } else if (this.key === 'driver' && window.location.hash.indexOf("driver-communication") >=0) {
                            this.fetchDriverReplyList()
                        } else {
                            firstInfo = {
                                content: res.content,
                                creationTime: res.creationTime,
                                messageType: res.messageType,
                                createdByName: res.createdByName || res.driverPhone,
                                filePath: res.filePath || "",
                                judge: res.judge || '0'
                            }
                            res[that.keyData[that.key].listName].forEach(function(v) {
                                if (!v.createdByName && v.driverPhone)
                                    v.createdByName = v.driverPhone;
                            });
                            that.replyList = res[that.keyData[that.key].listName];
                            console.log(that.replyList)
                            that.replyList.unshift(firstInfo);
                            console.log(that.replyList)
                        }
                        if(!this.toGroup) this.toGroup = res.sendCreatedByGroup
                        this.toGroupId = res.toGroupId
                        this.driverPostingInfo = res.driverPostingInfo || ''
                        that.$nextTick(() => {
                            that.$refs.infoList.scrollTop = that.$refs.infoList.scrollHeight;
                        });
                    }
                );
            }
            this.queryDriverMsg(this.curSelectedGroup.id || this.createdBy);
        },
        initEditor() {
            if (this.editor) {
                this.editor.txt.clear();
                return;
            }
            var that = this;
            this.editor = new Editor(
                "#editor_tool" + this.initId,
                "#editor_text" + this.initId
            );
            this.editor.customConfig.menus = this.config.editorTool || [];
            // this.editor.customConfig.uploadImgShowBase64 = true;
            // this.editor.customConfig.uploadImgServer = this.uploadImgUrl;
            this.editor.customConfig.customUploadImg = async function (resultFiles, insertImgFn) {
                const result = await extraDriverCommunicationNew.uploadFile({file:resultFiles[0]})
                if (result.success){
                    insertImgFn(result.fileUrl)
                }else{
                    _.ui.notify({
                        message: result.msg || "上传失败",
                        type: "error"
                    });   
                }
                
            }
            this.editor.customConfig.pasteFilterStyle = false  //配置粘贴时去除样式（不知道为啥不生效）
            this.editor.customConfig.uploadImgHeaders = {
                "X-Requested-By": "eshipping"
            };
            this.editor.customConfig.pasteTextHandle = function (content) { //自定义粘贴数据的处理
            // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
            if (content == '' && !content) return ''
                var str = content
                str = str.replace(/<xml>[\s\S]*?<\/xml>/ig, '')
                str = str.replace(/<style>[\s\S]*?<\/style>/ig, '')
                str = str.replace(/<\/?[^>]*>/g, '')
                str = str.replace(/[ | ]*\n/g, '\n')
                str = str.replace(/&nbsp;/ig, '')
                console.log('****', content)
                console.log('****', str)
                return str
            }
            this.editor.customConfig.uploadFileName = "file";
            this.editor.customConfig.showLinkImg = false;
            this.editor.customConfig.uploadImgHooks = {
                error(xhr, editor) {
                    _.ui.notify({
                        message: "上传失败",
                        type: "error"
                    });
                },
                timeout(xhr, editor) {
                    _.ui.notify({
                        message: "上传超时",
                        type: "error"
                    });
                },
                customInsert(insertImg, result, editor) {
                    var url = result.filePath;
                    that.sendImages.push(url);
                    insertImg(url);
                }
            };
            this.editor.customConfig.onfocus = () => {
                that.isEditing = true;
            };
            this.editor.customConfig.onblur = () => {
                that.isEditing = false;
            };
            this.editor.create();
        },
        waitInfo(id) {
            if(!this.checkEmpty(this.editor.txt.html())) {
                _.ui.notify({
                    message: "您还有信息未发送",
                    type: "error"
                });
                return
            }
            var sendData = {
                parentId: id || this.config.id || "",
            };
            _.ui.mask();
            _.util.ajax_submit('/messenger/dispatcher/pending', sendData, function(res) {
                _.ui.unmask();
                if(res.success) {
                    _.ui.notify({
                        message: "生成待办成功",
                        type: "success"
                    });
                }
            })
        },
        async sendInfo(data,key,event) {
            if(!this.config.buttons || this.config.buttons.indexOf("send") == -1) return
            this.sendValue = this.editor.txt.html()
            if(event && event.keyCode==13){
                event.returnValue = false;
            }
            var sendData = "";
            var that = this;
            if (data) {
                sendData = data;
            } else {
                if (this.checkEmpty(this.sendValue)) {
                    _.ui.notify({
                        message: "内容不能为空",
                        type: "error"
                    });
                    this.$nextTick(() => {
                        this.editor.txt.clear();
                    });
                    return;
                }
                console.log('sendValue',this.sendValue)
                let content = this.translateEmoticon(this.sendValue,'send').replace(/</g, "{").replace(/>/g, "}");
                let imageList = [];
                this.sendImages.forEach(v => {
                    if (content.indexOf(v) > 0) {
                        imageList.push(v);
                    }
                });
                if(this.key == 'internal'){
                    let idVal = ''
                    if (this.initialId == this.config.containerId) {
                        idVal = that.curQuote.id || that.config.id
                    }
                    sendData = {
                        // id: that.config.id || "",
                        id: idVal,
                        containerId: this.config.containerId || '',
                        taskId: this.config.taskId || '',
                        content: content,
                        messageType: 'HTML',
                        replyCustomerName: this.config.replyCustomerName || '',
                        driverPostingInfo: this.initialId == this.config.containerId? that.driverPostingInfo : '',
                        parentId: that.curQuote.id || '',
                        sendCreatedByGroup: this.config.sendCreatedByGroup || '',
                        portId: this.config.portId || '',
                    };
                    sendData.toGroupId = that.toGroupId
                    // console.log("内部协同消息 -----")
                    if (this.replyData) { // 是回复
                        sendData.id = this.replyData.id
                        sendData.parentId = this.replyData.parentId
                        sendData.toGroup = this.replyData.sendCreatedByGroup
                        sendData.replyCustomerName = this.replyData.sendCreatedByName
                    }
                    if (this.config.businessType == 'SHIPPING_BOOKING_INQUIRY_ORDER'){
                        sendData.businessType = "shipping_booking_inquiry_order"
                        if (this.config.shippingPersonnel == 'sale'){
                            sendData.replyCustomerName = this.businessInfo.userName
                        }else{
                            sendData.replyCustomerName = this.saleInfo.saleName
                        }
                    }
                    
                }else{
                    if(this.isCustomerBox){
                        sendData = {
                            parentId: that.config.id || "",
                            content: content,
                            customerId: that.config.customerId,
                            filePathList: imageList
                        };
                    }else {
                        sendData = {
                            parentId: that.curQuote.id  || "",
                            content: content,
                            customerId: that.createdBy,
                            filePathList: imageList,
                            containerId: that.config.containerId || -1
                        };
                    }
                }
            }
            _.ui.mask();
            
            const payload = _.util.removeEmptyValue(sendData)
            // if (that.config.key==='driver' && that.config.documentId === 'ask-leave') {
            //     Object.assign(payload, {
            //         dispatcherGroup: "VIP管理/推广",
            //         containerId: -1
            //     })
            // }

            if (that.key === 'driver') {
                try {
                    let questData = Object.assign({
                        messageType: 'HTML',
                        takerId: that.createdBy,
                        // parentDriverPostingId: that.curQuote.id  || "",
                        filePathList: [],
                        // ifNeedReceipt: true,
                    }, _.pick(payload, ['containerId', 'filePathList', 'content']), {
                        // dispatchGroupName: "司机服务组"
                        taskId: _.get(that, 'taskInfoList[0].taskId', null),
                        // containerId: _.get(that, 'taskInfoList[0].containerId', null),
                        containerId: that.config.containerId || '',
                        portId: _.get(that, 'taskInfoList[0].portId', undefined),
                    })
                    if (this.config.documentId == 'ask-leave') {
                        questData.portId = this.config.portId
                    }
                    let res = await replyDriverMessage(questData)
                    cb()
                } catch (error) {
                    
                } finally {
                    _.ui.unmask();
                }
                return 
            }

            // payload.toGroup = payload.sendCreatedByGroup
            payload.toGroupId = this.config.toGroupId ? this.config.toGroupId : this.config.dispatchGroupId
            payload.baseDispatchGroupCode = this.config.baseDispatchGroupCode
            payload.routeDispatchGroupId = this.config.routeDispatchGroupId
            delete payload.toGroup
            if (payload.sendCreatedByGroup) { // 内部协同页面进入
                delete payload.sendCreatedByGroup
            } else { // 小人头内部协同
                payload.source = 'head-icon'
            }
            _.util.ajax_submit(
                that.keyData[that.key].reply,
                payload,
                res => {
                    _.ui.unmask();
                    if (!res.success) {
                        _.ui.notify({
                            message: res.message || "发送失败",
                            type: "error"
                        });
                        return;
                    }
                    cb(res)
                }
            );
            
            function cb(res) {
                if (key == "file") {
                    that.replyList.push({
                        content: data.content,
                        createdBy: esapp.user.userName,
                        filePath: data.filePath,
                        messageType: "FILE"
                    });
                } else if (key == "containerOrder") {
                    that.replyList.push({
                        content: data.content,
                        createdBy: esapp.user.userName
                    });
                    that.blShow = false;
                } else {
                    that.replyList.push({
                        content: that.sendValue,
                        beRepliedPostingMap:that.curQuote.content,
                        createdBy: esapp.user.userName,
                        judge: '1'
                    });
                }
                console.log('that.replyList-------->',that.replyList);
                that.editor.txt.clear();
                that.sendValue = "";
                that.sendImages = [];
                that.curQuote.content = ""
                that.curQuote.id = ""
                that.replyData = null
                that.$nextTick(() => {
                    that.$refs.infoList.scrollTop =
                        that.$refs.infoList.scrollHeight;
                });
            }
        },
        checkEmpty(html) {
            var checkValue = html
                .replace(/\s+/g, "")
                .replace(/&nbsp;/g, "")
                .replace(/<p><\/p>/g, "")
                .replace(/<p><br><\/p>/g, "");
            if (!checkValue) {
                return true
            }
        },
        translateEmoticon(html,key) {
            if(this.checkEmpty(html)) return html
            if(key == 'received'){
                for(let k in this.emoticonsList){
                    let img = '<img class="emoticon-bg ' + this.emoticonsList[k] + '" src="../images/space.png">'
                    // html = html.replace(new RegExp(k, 'g'),img)
                    while(html.indexOf(k) != -1){
                        html = html.replace(k,img)
                    }
                }
                return html
            }else if(key == 'send'){
                for(let k in this.emoticonsList){
                    let img = '<img class="emoticon-bg ' + this.emoticonsList[k] + '" src="../images/space.png">'
                    // html = html.replace(new RegExp(img, 'gm'),k)
                    while(html.indexOf(img) != -1){
                        html = html.replace(img,k)
                    }
                }
                return html
            }
        },
        reqInfo(){
            var that = this;
            var state = false
            this.replyList.forEach(function (item) {
                if (!item.judge || item.judge == 1){
                    state = true
                }
            })
            if (!state) {
                that.modalReq.fade = true;
            }else{
                that.complete()
            }
        },
        complete() {
            var that = this;
            // 组装值
            this.sendValue = this.editor.txt.html();
            if(this.checkEmpty(this.sendValue)) this.sendValue = ""; // 数据清理
            let sendData = "";
            let content = this.translateEmoticon(this.sendValue,'send').replace(/</g, "{").replace(/>/g, "}");
            let imageList = [];
            this.sendImages.forEach(v => {
                if (content.indexOf(v) > 0) {
                    imageList.push(v);
                }
            });
            if(this.key == 'internal'){
                sendData = {
                    id: that.config.id || "",
                    containerId: this.config.containerId || '',
                    content: this.config.containerId ?content :'',
                    messageType: 'HTML',
                    toGroup: this.toGroup,
                    replyCustomerName: this.config.replyCustomerName || '',
                };
            } else if (this.key == 'driver' && window.location.hash.indexOf("driver-communication") >=0) {
                sendData = {
                    parentId: this.curCompleteId || "",
                    content: content,
                    customerId: that.createdBy,
                    filePathList: imageList
                };
            }else{
                sendData = {
                    parentId: that.config.id || "",
                    content: content,
                    customerId: that.createdBy,
                    filePathList: imageList
                };
            }
            
            _.ui.mask();
            _.util.ajax_submit(
                that.keyData[that.key].complete,
                _.util.removeEmptyValue(sendData),
                res => {
                    _.ui.unmask();
                    if (!res.success) {
                        _.ui.notify({
                            message: res.message || "完成失败",
                            type: "error"
                        });
                        return;
                    }
                    _.ui.notify({
                        message: "已完成",
                        type: "success"
                    });
                    that.sendValue = "";
                    // that.editor.txt.clear()
                    that.sendImages = [];
                    that.modalReq.fade = false;

                    if (that.checkDriverCommunication()) {
                        that.fetchDriverReplyList()
                    } else {
                        that.toggleModal();
                    }
                }
            );
        },
        /**
         * @desc 完成所有待办事项
         */
        handleCompleteAllTask() {
            var that = this;
            let imageList = [];
            this.sendImages.forEach(v => {
                if (content.indexOf(v) > 0) {
                    imageList.push(v);
                }
            });
            console.log(this.replyList)
            let payload = this.replyList
                .filter(item => !_.has(item, "createdBy"))
                .map(item => {
                    return {
                        parentId: item.id,
                        customerId: that.config.oppositeId,
                        filePathList: imageList
                    }
                })
            _.ui.mask();
            _.util.ajax_submit(
                "/messenger/dispatcher/batch/completed",
                {
                    replyMessageDtos: payload
                },
                res => {
                    _.ui.unmask();
                    if (!res.success) {
                        _.ui.notify({
                            message: res.message || "完成失败",
                            type: "error"
                        });
                        return;
                    }
                    _.ui.notify({
                        message: "已完成",
                        type: "success"
                    });

                    that.sendValue = "";
                    that.sendImages = [];
                    that.toggleModal();

                    that.modalReq.fade = false;
                    that.fetchDriverReplyList()
                }
            );
        },
        analysisContent(val) {
            let ret = val.replace(/{/g, "<").replace(/}/g, ">");
            return this.translateEmoticon(ret,'received');
        },
        fileClick() {
            this.$refs.fileInput.click();
        },
        async getFile(event) {
            if (!event.target.files[0]) return;

            var that = this;
            var fileName = event.target.files[0].name || "";
            var ext = fileName.substr(fileName.lastIndexOf(".")).toLowerCase();

            if (event.target.files[0] / 1024 > 2048) {
                _.ui.notify({
                    message: "请上传小于2MB的文件",
                    type: "error"
                });
                return;
            }

            // var formData = new FormData();
            // formData.append("file", event.target.files[0]);
            // formData.append("fileType", ext);

            _.ui.mask();

            const result = await extraDriverCommunicationNew.uploadFile({file:event.target.files[0]})
            
            if (result.success){
                let sendData = ''
                if(that.key == 'internal'){
                    sendData = {
                        content: fileName,
                        messageType: 'FILE',
                        filePath: result.fileUrl,
                        toGroup: that.toGroup,
                        containerId: that.config.containerId,
                        id: that.config.id
                    }
                    if (that.config.businessType == 'SHIPPING_BOOKING_INQUIRY_ORDER'){
                        sendData.businessType = "shipping_booking_inquiry_order"
                        if (that.config.shippingPersonnel == 'sale'){
                            sendData.replyCustomerName = that.businessInfo.userName
                        }else{
                            sendData.replyCustomerName = that.saleInfo.saleName
                            
                        }
                    }
                }else{
                    sendData = {
                        parentId: that.config.id || "",
                        content: fileName,
                        customerId: that.createdBy,
                        filePath: result.fileUrl
                    }
                }
                // console.log("发送消息 发送文件----")
                that.sendInfo(sendData, "file");
            }else{
                _.ui.notify({
                    message: result.msg || "上传失败",
                    type: "error"
                });  
                _.ui.unmask(); 
            }

            // $.ajax({
            //     url: "/ws-truck/messenger/upload/file",
            //     type: "POST",
            //     data: formData,
            //     headers: {
            //         "X-Requested-By": "eshipping"
            //     },
            //     cache: false,
            //     contentType: false,
            //     processData: false,
            //     success(res) {
            //         let sendData = ''
            //         if(that.key == 'internal'){
            //             sendData = {
            //                 content: fileName,
            //                 messageType: 'FILE',
            //                 filePath: res.filePath,
            //                 toGroup: that.toGroup,
            //                 containerId: that.config.containerId,
            //                 id: that.config.id
            //             }
            //             if (that.config.businessType == 'shipping_booking_inquiry_order'){
            //                 sendData.businessType = "shipping_booking_inquiry_order"
            //                 if (that.config.shippingPersonnel == 'sale'){
            //                     sendData.replyCustomerName = that.businessInfo.userName
            //                 }else{
            //                     sendData.replyCustomerName = that.saleInfo.saleName
                                
            //                 }
            //             }
            //         }else{
            //             sendData = {
            //                 parentId: that.config.id || "",
            //                 content: fileName,
            //                 customerId: that.createdBy,
            //                 filePath: res.filePath
            //             }
            //         }
            //         // console.log("发送消息 发送文件----")
            //         that.sendInfo(sendData, "file");
            //     },
            //     error(res) {
            //         _.ui.notify({
            //             message: "上传失败",
            //             type: "error"
            //         });
            //         _.ui.unmask();
            //     }
            // });
        },
        downloadFile(name, url) {
            console.log(name);
            url =
                window.location.origin +
                "/ws-truck/messenger/download/file?filePath=" +
                url +
                "&fileName=" +
                name;
            // window.open(url,'_black');
            window.location.href = url;
        },
        showImg(event) {
            if (event.target.nodeName == "IMG") {
                this.imagesDetailList = [];
                this.imagesDetailList.push({
                    imagePath: event.target.src
                });
                this.imgShow = true;
            }
        },
        delReply() {
            this.curQuote.content = ''
            this.curQuote.id = ''
            this.replyData = null
        },
        changePre() {
            this.delReply()
            this.$emit(
                "changeinfo",
                "pre",
                this.config.id || this.config.taskId
            );
        },
        changeNext() {
            this.delReply()
            this.$emit(
                "changeinfo",
                "next",
                this.config.id || this.config.taskId
            );
        },
        hisScroll(event) {
            if (
                event.target.scrollTop < 5 &&
                this.pageTotal > this.hisInfoList.length &&
                !this.isReqHis
            ) {
                this.pageNum += 1;
                this.isReqHis = true;
                this.getHisInfo(this.pageNum);
            }
        },
        getInput(key, event) {
            this[key] = event;
        },
        confirmBl() {
            if (!this.blNo) {
                _.ui.notify({
                    message: "提单号不能为空",
                    type: "error"
                });
                return;
            }
            var html =
                "{a href='http://www.carrierglobe.com/truck-saas/packing-list-search.html?blNo=" +
                this.blNo +
                "&queryNo=u0aaaQ' style='font-style:italic' target='_blank'}点击查看：提单号" +
                this.blNo +
                "装箱单信息{/a}";
            let sendData = '';
            if(this.key == 'internal'){
                sendData = {
                    id: that.config.id || "",
                    containerId: this.config.containerId,
                    content: html,
                    messageType: 'HTML',
                    toGroup: this.toGroup
                };
            }else{
                sendData = {
                    parentId: this.config.id || "",
                    content: html,
                    customerId: this.createdBy
                }
            }
            // console.log("发送消息 输入提单号 ----- ")
            this.sendInfo(sendData, "containerOrder");
        },
        isEmptyObject(obj) {
            return _.util.isEmptyObject(obj)
        },
        addEmoticon(val) {
            this.emoticonModal.fade = false
            var img = '<img class="emoticon-bg ' + this.emoticonsList[val] + '" src="../images/space.png">'
            console.log(img)
            this.editor.cmd.do('insertHTML', img)
        },
        bodyClick(e) {
            if(this.emoticonModal.fade == false) return
            let className = e.target.className
            if(className.indexOf('emoticon-table') != -1 || className.indexOf('emoticon-item') != -1 || className.indexOf('fa-smile-o') != -1) return
            this.emoticonModal.fade = false
        },
        chatTo(key, item, parentItem, info) {
            this.curSelectedGroup = item
            let taskId = ""
            let takerId = ""
            
            // 司机 属于司机热线
            if(key == '司机' ||  parentItem.name == '接单司机') {
            // if(key == '司机') {
                this.key = 'driver',
                // this.title = this.config.takerDriver;
                // this.title = this.title;
                this.getDriverInfo(item.id)
                takerId = item.id
                taskId = item.taskId
            }else if(parentItem.name == '客户'){
                this.key = 'customer';
                this.title = this.config.createdName;
                this.isCustomerBox = true;
                this.config.oppositeId = this.title?this.title.split('--')[0]:'';
                this.config.containerId = ''
            }else {
                this.key = 'internal'
                this.toGroup = key
                this.title = key;
                if (parentItem.name === '客服组') {
                    this.toGroup = "客服组"
                    this.toGroupId = 6
                    this.title = item.customerService;
                }
                if (parentItem.name === '司机客服组') {
                    this.toGroup = parentItem.name
                    this.toGroupId = parentItem.id
                    this.title = item.customerService;
                }
                if (parentItem.name === '销售组') {
                    this.toGroup = parentItem.name
                    this.toGroupId = 18
                    this.title = `${parentItem.name}-${item.saleName}`
                }
                if (parentItem.name === '商务组') {
                    this.toGroup = parentItem.name
                    this.toGroupId = 19
                    this.title = `${parentItem.name}-${item.userName}`
                }

                // 正常组, 大组下面只有一个的话, 直接选大组
                if(['智能调度组', '运力运营组', '安全管理组', '增值服务组'].includes(parentItem.name)) {
                    this.toGroup = parentItem.name;
                    this.toGroupId = parentItem.id
                    this.title = parentItem.name;
                }
                if(this.config.editorTool.indexOf("file") == -1) this.config.editorTool.push('file')
            }
            

            // 客服组，接单司机的toGroupId是固定值
            this.config.toGroupId = null
            if (parentItem && parentItem.name) {
                this.config.toGroupId = parentItem.name == '客服组' ? 6 : parentItem.name == '接单司机' ? 1 :parentItem.id
                
            }
            
            if (parentItem && parentItem.id === 111 && parentItem.secondChildren && parentItem.secondChildren.length > 0 && info) { // 线路港口-司机服务部 线路非必填，受理组必填
                // 司机服务部
                this.config.baseDispatchGroupCode = item.code || this.config.baseDispatchGroupCode
                this.config.routeDispatchGroupId = item.id || this.config.routeDispatchGroupId
                // console.log("司机服务部-----")
                if(info.childType == 'child') {
                    this.selectedBtnIndexChild = `p${info.itemIndex}c${info.childIndex}`
                    this.messageData.childName = item.name
                } else {
                    this.selectedBtnIndexSecondChild = `p${info.itemIndex}sc${info.childIndex}`
                    this.messageData.secondChildName = item.name
                }
                return
            } else {
                // 非司机服务部
                this.config.baseDispatchGroupCode = item.code
                this.config.routeDispatchGroupId = item.id
                // console.log("非司机服务部-----")
            }
            // console.log("非司机服务部----- nextTick")
            this.modalChoose.fade = false
            this.contentShow = true
            this.$nextTick(() => {
                this.initInfo(taskId,takerId);
            })
        },
        driverInfoCommDriver() {
            this.$emit('changedrivercomm', this.config.driverInfoCanCommDriver)
        },
        // 司机热线单条聊天回复选取
        handleReply(item) {
            this.hasReplyIcon = true // 启用回复
            console.log(item)
            this.curQuote.content = this.analysisContent(item.content)
            this.curQuote.id = item.id
            this.replyData = item
        },
        /**
         * @desc 记录单条聊天语句
         */
        handleCompleteTask(item) {
            this.curCompleteId = item.id
            this.complete()
        },
        /**
         * @deprecated
         * @获取司机未完成任务数量
         */
        getUndoCount() {
            if (this.checkDriverCommunication()) {
                _.util.ajax_get("/messenger/waiting/dispose/messageCount", {}, res => {
                    this.unhandleCount = _.get(res, 'data.value', 0)
                })
            }
        },
        /**
         * @deprecated
         * @desc 是否是司机热线页面
         */
        checkDriverCommunication() {
            return this.key == 'driver' && window.location.hash.indexOf("driver-communication-detail") >=0
        },
        /**
         * @desc 生成待办
         */
        handleTodo(item) {
            this.waitInfo(item.id)
        },
        /**
         * @desc 移交
         */
        handleTurnOver(item) {
            this.$emit("turn-over", item) //
        },
        handleTabDateChange(date) {
            console.log(date)
            let { start } = date
            if (start) {
                // 获取新的订单
                this.fetchOrderList(start)
            }
        },
        /**
         * @desc 获取订单列表
         */
        fetchOrderList(date) {
            
            let d = date
            if (!_.isString(date)) {
                d = getNowFormatDate(date)
            }
            _.util.ajax_get(`/messenger/driver/orderInfo`, {
                driverId: this.config.oppositeId,
                planArrivalTime: d
            }, res => {
                console.log(res)
                if (res.data) {
                    res = eval("(" + res.data.value + ")");
                    res.forEach(function(v){
                        v.planArrivalTime = _.util.dateFormat(v.planArrivalTime)
                    })
                    if(!this.config.containerId) {
                        this.config.containerId = res[0].containerId;
                    }
                    this.taskInfoList = res || [];
                } else {
                    this.taskInfoList = []
                }
            });
            console.log('获取订单列表', this.taskInfoList)
        },
        fetchDriverReplyList() {
            // 获取司机当前对话框未处理任务
            _.util.ajax_get(`/messenger/driver/posting/unHandled/list`, {
                driverId: this.config.oppositeId,
                dispatcherGroup: this.config.dispatcherGroup,
            }, res => {
                console.log(JSON.parse(res.data.value))
                if (res.success) {
                    let data = JSON.parse(res.data.value)
                    let replyList = []
                    // 任务
                    data.forEach(task => {
                        replyList.push(_.pick(task, [
                            'content',
                            'createdByName',
                            'creationTime',
                            'filePath',
                            'judge',
                            'messageType',
                            'id',
                            'dispatcherGroup'
                        ]))
                    })
                    this.unhandleCount = data.length;
                    // 任务评论
                    let commentList = []
                    data.forEach(task => {
                        task.dispatcherReplyInfoDtos.forEach(reply => {
                            commentList.push(reply)
                        })
                    })
                    commentList.sort((a, b) => Date.parse(a.creationTime) - Date.parse(b.creationTime))
                    this.replyList = [
                        ...replyList,
                        ...commentList
                    ]
                }
            });
        },
        gotoCarTraceReport(containerNo,blNo){
          console.log("containerNo",containerNo)
          console.log("blNo",blNo)
          window.location.hash=`#car-trace-report-new?containerNo=${containerNo}&blNo=${blNo}`
        },
        /**
         * @desc 获取所有消息对象 选择组
         */
        async fetchGroupList() {
            let result = []
            try {
                const { containerId, createdName, taskId, portId,businessType } = this.config
                _.ui.mask();
                
                let caseData = await fetchDispatchGroupByCase({useCase: "SEND_MESSAGE", containerId: containerId || '', taskId: taskId || '', portId: portId || '',businessType:businessType || ''})
                result = result.concat(caseData.data.data)

                if (containerId && Number(containerId) > 0 && this.config.businessType != "SHIPPING_BOOKING_INQUIRY_ORDER") {
                    const resp = await fetchTakerByContainerId({containerId})
                    const map = {
                        "CONTAINER_NO_BOOK": "预提司机",
                        "TASK_LARGE": "门点司机",
                        "TASK_GATE_IN": "二进司机",

                        "TASK_GATE_IN_SHORT": "二进（进重）",       // 20 二进（进重） 
                        'TASK_SCHEDULED_ACCRUAL': "预提（提空）",   // 30 预提（提空）
                        'TASK_EMPTY_TRANS': "驳空（疏港）",         // 40 驳空（疏港）
                        'TASK_INTERIOR_EMPTY_TRANS': "仓库业务（提空）",// 50 仓库业务（提空）
                        'TASK_INTERIOR_GATE_IN': "仓库业务（进重）",    // 51 仓库业务（进重）
                        'TASK_INTERIOR_HEAVY_TRANS': "仓库业务（提重）",// 52 仓库业务（提重）
                        'TASK_INTERIOR_EMPTY_TURN': "仓库业务（还空）", // 53 仓库业务（还空）
                        'TASK_EMPTY_TRANS_TURN': "还空任务",    // 60 还空任务
                        'TASK_HEAVY_TRANS': "驳重（疏港）",     // 70 驳重（疏港）
                    }
                    let item = {
                        name: "接单司机",
                        children: resp.data.data.filter(v => !!v.id).map(v => ({
                            id: v.id,
                            taskId: v.taskId,
                            name: map[v.type] || "司机",
                            type: "DRIVER"
                        }))
                    }
                    if (item.children.length) {
                        result.push(item)
                    }
                }
                if ((containerId || taskId) && this.config.businessType != "SHIPPING_BOOKING_INQUIRY_ORDER") {
                    const customerServiceRsep = await fetchCustomerServiceByContainerIdOrTaskId({
                        containerId: this.config.containerId,
                        taskId: this.config.taskId
                    })
                    const customerServiceData = _.get(customerServiceRsep, 'data.data', {})
                    if (!_.isEmpty(customerServiceData)) {
                        const { planArrivalTime, doorAddress, customerService } = customerServiceData
                        result.unshift({
                            name: '客服组',
                            children: [
                                {
                                    name: `${planArrivalTime}  ${doorAddress}  ${customerService}`,
                                    planArrivalTime,
                                    customerService,
                                    doorAddress
                                }
                            ]
                        })
                    }
                }

                // 客户
                if (createdName && this.isCustomerButton && this.config.businessType != "SHIPPING_BOOKING_INQUIRY_ORDER") {
                    result.unshift({
                        name: '客户',
                        children: [
                            {
                                name: createdName
                            }
                        ]
                    })
                }
                // sb code
                // 司机客服组和客服组 children 保持一致
                const driverService = result.find(v => v.name === '司机客服组')
                const customerService = result.find(v => v.name === '客服组')
                if (customerService && driverService) {
                    driverService.children = _.cloneDeep(customerService.children)
                }

                if (this.config.businessType == "SHIPPING_BOOKING_INQUIRY_ORDER"){
                    // 如果是海运订舱类型的就只展示一个销售组
                    result = []
                    if (this.config.shippingPersonnel == 'sale'){
                        _.util.ajax_get("/../gw/customer-server/shipping/booking-inquiry/query-business-person-info", {
                            quotationId: this.config.taskId
                        }, res => {
                            this.businessInfo = res.data
                            result.unshift({
                                name: '商务组',
                                id:19,
                                children: [
                                    {
                                        name: `商务---${res.data.userName}`,
                                        userId:res.data.userId,
                                        userName:res.data.userName
                                    }
                                ]
                            })
                        });
                    }else{
                        _.util.ajax_get("/../gw/customer-server/shipping/booking-inquiry/query-sale-info", {
                            quotationId: this.config.taskId
                        }, res => {
                            this.saleInfo = res.data
                            result.unshift({
                                name: '销售组',
                                id:18,
                                children: [
                                    {
                                        name: `销售---${res.data.saleName}`,
                                        saleId:res.data.saleId,
                                        saleName:res.data.saleName
                                    }
                                ]
                            })
                        });
                    }
                    
                }
            } catch (error) {
                console.log(error)
                _.ui.unmask();
            } finally {
                this.chatGroupList = result
                _.ui.unmask();
            }
        }
    },
    watch: {
        config() {
            console.log("收到的config",this.config)
            if (!this.show || !this.config.key) return;
            this.key = this.config.key;
            this.isCustomerButton = this.config.isShowCustomer; //是否显示联系客户按钮
            this.companyName = this.config.clientName; //传公司名称
            this.toGroup = this.config.toGroup||'';
            this.pageNum = 1;
            this.replyList = []
            this.initialId = this.config.containerId
            if(this.needChoose){
                this.contentShow = false
                this.modalChoose.fade = true
                
            }else{
                const shouldChatToDriver = (location.hash.includes('ask-leave') || location.hash.includes('internal-coordination'))
                    && this.config.key === 'driver'
                if(shouldChatToDriver) {
                    this.chatTo('司机', {
                        id: this.config.oppositeId
                    })
                } else {
                    this.contentShow = true
                    this.initInfo();
                }
            }
            if (this.key != "internal" 
                && this.config.documentId != "ask-leave" 
                && this.config.documentId != "customer-communication") {
                this.fetchGroupList()
            }
            if (this.config.businessType == "SHIPPING_BOOKING_INQUIRY_ORDER") {
                this.fetchGroupList()
            }
        },
        /**
         * @deprecated
         */
        show (val) {
            if (this.checkDriverCommunication()) {
                console.log('测试watchshow',val,this.fetchOrderList(getNowFormatDate()))
                val && this.fetchOrderList(getNowFormatDate())
            }
        },
        'modalChoose.fade': {
            handler(newValue, oldValue) {
                if (this.modalChoose.fade) {
                    this.selectedBtnIndexChild = null
                    this.selectedBtnIndexSecondChild = null
                    this.messageData = {}
                }
            },
        }
    }
};
</script>
<style>
    #response-modal p {
        margin: 0;
    }
    #response-modal .modal-full {
        width: 1005px;
        max-width: inherit;
    }
    #response-modal .info-content {
        margin-bottom:5px;
        border:1px solid #888;
        border-radius:3px;
        width:100%;
    }
    #response-modal .communication-body {
        height:250px;
    }
    #response-modal .send-body {
        height:150px;
        position: relative;
    }
    #response-modal .send-body .emoticon-box {
        position: absolute;
        width:402px;
        height:200px;
        left:-5px;
        top:-199px;
        background-color: #fff;
        border:1px solid #000;
        box-shadow: 0 0 5px #888;
        z-index: 1;
    }
    #response-modal .send-body .emoticon-box .emoticon-header {
        height: 30px;
        background-color: #e0e0e0;
    }
    #response-modal .emoticon-box .emoticon-item {
        width: 20px;
        height: 20px;
        float: left;
        background-image: url(../images/emoticons.jpg);
        margin: 0 5px 5px 0;
        cursor: pointer;
    }
    #response-modal .emoticon-bg {
        width: 20px;
        height: 20px;
        background-image: url(../images/emoticons.jpg);
        cursor: auto;
    }
    #response-modal .send-body .message-nowrap {
        display: inline-block;
        width: 98%;
    }
    #response-modal .send-body .message-nowrap-c {
        width: 94%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    #response-modal .emoticon-bg:hover {
        box-shadow: none;
    }
    #response-modal .note {
        margin-bottom: 5px;
        padding: 5px 30px 5px 15px;
        width: 100%;
        float: left;
    }
    #response-modal .editor-tool {
        height: 30px;
        border: none;
        padding-right: 0;
        overflow: hidden;
        float: left;
    }
    #response-modal .change-info {
        height: 30px;
        border: none;
        overflow: hidden;
        float: right;
    }
    #response-modal .change-info .change-item{
        height: 30px;
        line-height: 30px;
        padding: 0 5px;
        border: none;
        overflow: hidden;
        float: right;
    }
    #response-modal .tool {
        height:30px;
        float: left;
    }
    #response-modal .tool .tool-item {
        width:33px;
        height: 100%;
        color: #999;
        padding: 5px 10px;
        cursor: pointer;
        font-size: 14px;
        line-height: 16px;
        float: left;
        position: relative;
    }
    #response-modal .tool .tool-item:hover {
        color: #000;
    }
    #response-modal .tool .tool-item .tool-input {
        display: none;
    }
    #response-modal .btn {
        padding:6px 30px;
    }
    /* #response-modal textarea {
        outline:none;
        resize:none;
        width:100%;
        height:80px;
        border:none;
    } */
#response-modal .editor-text {
    outline: none;
    resize: none;
    width: 100%;
    height: 115px;
    border: none;
}
#response-modal .editor-text > div {
    overflow-y: auto;
}
#response-modal .editor-text img {
    max-width: 200px;
    vertical-align: bottom;
}
#response-modal .info-item {
    overflow: hidden;
    width: 100%;
    padding: 10px;
}
#response-modal .info-item .right-info {
    float: right;
    background-color: #a9eee0;
}
#response-modal .info-item .left-info {
    float: left;
    background-color: #f9f9f9;
    position: relative;
}
#response-modal .info-item .quote-reply {
    margin-bottom: 2px;
    padding-left: 4px;
    color: #949494;
    border-left: 3px solid #949494;
    white-space: normal;
    word-break: break-all;
}
#response-modal .info-item .left-info-box {
    display:flex;
    align-items: center;
}
#response-modal .info-item .flex-direction {
    flex-direction: row-reverse;
}
#response-modal .info-item .reply-btn {
    margin-left: 10px;
    margin-right: 10px;
    color: #3a83e4;
    cursor: pointer;
}

#response-modal .info-item .reply-toolbox {
    position: absolute;
    top: 6px;
    right: -150px;
    background-color: transparent;
    color: blue;
}
#response-modal .info-item .reply-toolbox span {
    cursor: pointer;
}

#response-modal .quote {
    position: relative;
    padding: 6px 10px;
    color: #333;
    border-bottom: 2px dashed #999;
    border-top: 2px solid #999;
}
#response-modal .quote img {
    max-width: 60px;
    max-height: 40px;
}
#response-modal .five-day-picker {
    display: flex!important;
    align-items: center;
    margin-bottom: 10px;
}
#response-modal .five-day-picker .input-date  {
    display: inline-flex!important;
    align-items: center;
}

#response-modal .info-item img {
    max-width: 100%;
    vertical-align: bottom;
}
#response-modal .info-item .info-file {
    width: 180px;
    height: 60px;
    border: 1px solid #000;
    position: relative;
}
#response-modal .info-item .info-file i {
    position: absolute;
    top: 0;
    left: 10px;
    width: 32px;
    height: 60px;
    font-size: 34px;
    line-height: 60px;
    color: #333;
}
#response-modal .info-item .info-file-name {
    position: absolute;
    top: 5px;
    left: 50px;
    width: 120px;
    height: 35px;
    font-size: 12px;
    overflow: hidden;
}
#response-modal .info-item .info-file-name.file-note {
    top: 40px;
    height: 15px;
    font-weight: bold;
}
#response-modal .time {
    width: 100%;
    float: left;
    text-align: center;
    color: #ccc;
}
#response-modal .avatar {
    margin-top: 5px;
    width: 40px;
    height: 40px;
    border: 1px solid #000;
}
#response-modal .info {
    max-width: 300px;
    border-radius: 10px;
    /* margin:10px; */
    margin-top: 5px;
    padding: 6px;
}
#response-modal .info-img {
    max-width: 288px;
}
#response-modal .info-audio {
    text-decoration: none;
    font-size: 40px;
    line-height: 40px;
}
#response-modal .other-info-btn {
    padding: 3px 6px;
    border: 1px solid #333;
    float: left;
    margin-right: 5px;
}
#response-modal .reply-info img {
    max-width: 150px;
}
#response-modal .fr {
    float: right;
}
#response-modal .fl {
    float: left;
}
#response-modal .telCircle{
    width: 18px;
    height: 18px;
    border: 1px solid rgb(51, 122, 183);
    border-radius: 50%;
    line-height: 18px;
    text-align: center;
    vertical-align: text-top;
}
#response-modal .unhandle-count{
    color: red;
    float: right;
    font-size: 16px;
}
.response-group-modal .group-list {
    
}
.response-group-modal .group-list .group-item{
    margin-bottom: 20px;
}
.response-group-modal .group-list .group-title{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
    border-left: 4px solid red;
    padding-left: 10px;
}
.group-list .group-item .child-list .elBtnActive{
    color: #3a83e4 !important;
    background:#ECF5FF !important;
}
.secondChild-list{
    margin-top: 10px;
}
</style>